<template>
  <!-- Tools -->
  <div>
    <a href="#" class="bold black" @click="popupActive = true">¿Qué son los límites?</a>
    <!-- MODAL DISPERSIONES -->
    <vs-popup class="mid-popup no-header" title="" :active.sync="popupActive">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col w-full">
            <h2>¿Qué son los límites de tu cuenta?</h2><br>
            <label>
              Los límites de cuenta te permiten ir completando gradualmente tu expediente y a 
              su vez operar la cuenta basada en los límites que te mostramos a continuación. 
              Estos límites son definidos por las autoridades y se basan en Unidades de Inversión (UDIs) 
              al cierre del mes anterior.
            </label>
            <br><br>
            <p>
              El límite de la cuenta es la inversión efectiva y el límite de operación mensual considera los abonos realizados a su cuenta en RedGirasol.
            </p>
          </div>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col w-full md:w-1/3 padding-sides">
            <div class="vx-row">
              <div class="vx-col w-full text-center border-green">
                <div class="vx-row level-header">
                  <div class="vx-col w-full mt-2 mb-2">
                    <label class="bold">
                      Nivel 1
                    </label>
                  </div>
                </div>
                <div v-if="currentLevel == 1" class="vx-row level-subheader">
                  <div class="vx-col w-full mt-2 mb-2">
                    <label>
                      Tu nivel actual
                    </label>
                  </div>
                </div>
                <div class="vx-row mt-base">
                  <div class="vx-col w-full mb-5">
                    <h2>
                      {{explicitMoneyFormat(accountLimitOne)}}
                    </h2>
                    <label>
                      Límite de la Cuenta 
                    </label>
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <div class="vx-col w-full">
                    <h2>
                      {{explicitMoneyFormat(monthlyLimitAmountOne)}}
                    </h2>
                    <label>
                      Límite de operación mensual
                    </label>
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <div class="vx-col w-full">
                    <vs-divider color="#188414"></vs-divider>
                  </div>
                </div>
                <div class="vx-row mt-5 mb-5">
                  <div class="vx-col w-full">
                    <h5>
                      Requisitos
                    </h5>
                    <div class="flex text-left mt-2 mb-5">
                      <feather-icon v-if="levelOneCompleted" icon="CheckIcon"></feather-icon>
                      <feather-icon v-else icon="ChevronRightIcon"></feather-icon>
                      <label>Datos personales básicos</label>
                    </div>                  
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="vx-col w-full md:w-1/3 padding-sides">
            <div class="vx-row">
              <div class="vx-col w-full text-center border-green">
                <div class="vx-row level-header">
                  <div class="vx-col w-full mt-2 mb-2">
                    <label class="bold">
                      Nivel 2
                    </label>
                  </div>
                </div>
                <div v-if="currentLevel == 2" class="vx-row level-subheader">
                  <div class="vx-col w-full mt-2 mb-2">
                    <label>
                      Tu nivel actual
                    </label>
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <div class="vx-col w-full mb-5">
                    <h2>
                      Sin límite
                    </h2>
                    <label>
                      Límite de la Cuenta 
                    </label>
                  </div>
                </div>
                <div class="vx-row mt-2">
                  <div class="vx-col w-full">
                    <h2>
                      {{explicitMoneyFormat(monthlyLimitAmountTwo)}}
                    </h2>
                    <label>
                      Límite de operación mensual
                    </label>
                  </div>
                </div>
                <div class="vx-row mt-2">
                  <div class="vx-col w-full">
                    <vs-divider color="#188414"></vs-divider>
                  </div>
                </div>
                <div class="vx-row mt-2 mb-5">
                  <div class="vx-col w-full">
                    <h5>
                      Requisitos
                    </h5>
                    <div class="flex text-left mt-2">
                      <feather-icon v-if="levelTwoCompleted" icon="CheckIcon"></feather-icon>
                      <feather-icon v-else icon="ChevronRightIcon"></feather-icon>
                      <label>Domicilio</label>
                    </div>   
                    <div class="flex text-left">
                      <feather-icon v-if="levelTwoCompleted" icon="CheckIcon"></feather-icon>
                      <feather-icon v-else icon="ChevronRightIcon"></feather-icon>
                      <label>Identificación oficial</label>
                    </div>                  
                  </div>
                </div>
                <div class="vx-row mb-5">
                  <div class="vx-col w-full">
                    <vs-button
                    id="raise_limit_btn"
                    :disabled="currentLevel != 1"
                    color="success"
                    type="filled"
                    @click.native="goToIncrementLevel"
                    >Aumentar límite</vs-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="vx-col w-full md:w-1/3 padding-sides">
            <div class="vx-row">
              <div class="vx-col w-full text-center border-green">
                <div class="vx-row level-header">
                  <div class="vx-col w-full mt-2 mb-2">
                    <label class="bold">
                      Nivel 3
                    </label>
                  </div>
                </div>
                <div v-if="currentLevel == 3" class="vx-row level-subheader">
                  <div class="vx-col w-full mt-2 mb-2">
                    <label>
                      Tu nivel actual
                    </label>
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <div class="vx-col w-full mb-5">
                    <h2>
                      Sin límite
                    </h2>
                    <label>
                      Límite de la Cuenta 
                    </label>
                  </div>
                </div>
                <div class="vx-row mt-2">
                  <div class="vx-col w-full">
                    <h2>
                      Sin límite
                    </h2>
                    <label>
                      Límite de operación mensual
                    </label>
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <div class="vx-col w-full">
                    <vs-divider color="#188414"></vs-divider>
                  </div>
                </div>
                <div class="vx-row mt-5 mb-5">
                  <div class="vx-col w-full">
                    <h5>
                      Requisitos
                    </h5>
                    <div class="flex text-left mt-2">
                      <feather-icon v-if="levelThreeCompleted" icon="CheckIcon"></feather-icon>
                      <feather-icon v-else icon="ChevronRightIcon"></feather-icon>
                      <label>Validar cuenta bancaria</label>
                    </div>                  
                  </div>
                </div>
                <div class="vx-row mb-5">
                  <div class="vx-col w-full">
                    <vs-button
                    :disabled="currentLevel != 2"
                    color="success"
                    type="filled"
                    @click.native="goToPayFunds"
                    >Agregar fondos</vs-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </vs-popup>
    <!--  -->
  </div>
</template>

<script>
import formatHelper from '@mixins/formatHelper';
export default {
  name: "ContractLevelsInfo",
  props: {
    currentLevel: {
      type: Number,
      required: true
    },
    monthlyLimitAmountOne: {
      type: Number,
      required: true
    },
    monthlyLimitAmountTwo: {
      type: Number,
      required: true
    },
    accountLimitOne: {
      type: Number,
      required: true
    },
  },
  mixins: [formatHelper],
  data() {
      return {
          popupActive: false
      }
  },
  computed: {
    levelOneCompleted(){
      return this.currentLevel >= 1;
    },
    levelTwoCompleted(){
      return this.currentLevel >= 2;
    },
    levelThreeCompleted(){
      return this.currentLevel >= 3;
    }
  },
  methods: {
    async goToIncrementLevel(){
      this.popupActive = false;
      await this.$router.replace({name: 'incrementInvestorContractLevel'});
    },
    async goToPayFunds(){
      this.popupActive = false;
      await this.$router.replace({name: 'abonarFondos'});
    },
  },
};
</script>
<style lang="css">
  .level-header{
    background-color: #188414;
    border-radius: 8px 8px 0px 0px;
    color: white;
  }
  .level-subheader{
    background-color: #badbb9;
    color: #188414;
  }
  .border-green{
    border-color: #188414;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
  }

  .padding-sides{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
</style>