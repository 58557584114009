<template>
   <div>
      <h2 class="mb-2">Completa los siguientes pasos ({{ secondaryMarketStepsCount }}/2):</h2>
      <div class="vx-row mt-base">
         <div class="vx-col w-full">
            <div class="flex flex-wrap items-center mb-2">
               <feather-icon v-if="minInvestmentToSecondaryMarketReached" icon="CheckCircleIcon"
                  svgClasses="w-8 h-8 text-primary mr-2" />
               <feather-icon v-else icon="CheckCircleIcon" svgClasses="w-8 h-8 text-gray mr-2" />
               <h3 class="bold">Alcanza la inversión mínima de $5,000</h3>
            </div>
            <p class="bold">
               <template v-if="minInvestmentToSecondaryMarketReached">Mantén el mínimo de inversión para permanecer en
                  la
                  lista de espera.</template>
               <template v-else>Te faltan: {{ explicitMoneyFormat(minInvestmentToSecondaryMarket) }}</template>
            </p>
            <vs-button v-if="!minInvestmentToSecondaryMarketReached" class="mt-5" color="dark"
               @click.stop="popUpPayFunds = true">Abonar</vs-button>
         </div>
      </div>
      <div class="vx-row mt-base mb-1">
         <div class="vx-col w-full">
            <div class="flex flex-wrap items-center mb-2">
               <feather-icon v-if="InvestorSecondaryMarketContractSigned" icon="CheckCircleIcon"
                  svgClasses="w-8 h-8 text-primary mr-2" />
               <feather-icon v-else icon="CheckCircleIcon" svgClasses="w-8 h-8 text-gray mr-2" />
               <h3 class="bold">Firma tu nuevo contrato</h3>
            </div>
            <template v-if="!InvestorSecondaryMarketContractSigned">
               <p class="bold">Alcanza la inversión mínima para poder firmar.</p>
               <vs-button class="mt-3" :disabled="!canSignSecondaryMarketContract" color="dark" @click.stop="showSecondaryMarketPopUp">Firmar</vs-button>
            </template>
            <template v-else>
               <p class="bold">Ya cuentas con tu contrato firmado.</p>
            </template>
         </div>
      </div>

      <!-- POP-UP SOBRE INVITACION AL MERCADO SECUNDARIO -->
      <vs-popup :active.sync="popUpSecondaryMarketInvitationActive" :title="popUpSecondaryMarketInvitationTitleDynamic"
         class="header-primary mid-popup">
         <div v-if="popUpSecondaryMarketInvitationActive" class="vx-row">
            <div class="vx-col w-full p-10">
               <div v-if="popUpSecondaryMarketStep == 1">
                  <p>
                     Este nuevo contrato te permitiriá <b>diversificar</b> tu inversión y obtener <b>liquidez
                        anticipada</b>
                     (mercado secundario).
                     <br><br>
                     Estas son las nuevas comisiones:
                  </p>
                  <div class="vx-row">
                     <div class="vx-col w-full" style="height:300px; overflow-y:scroll">
                        <vs-table class="mt-base" noDataText="">
                           <template>
                              <vs-tr>
                                 <vs-td class="bold">Comisión</vs-td>
                                 <vs-td class="bold">Antes</vs-td>
                                 <vs-td class="bold">Ahora</vs-td>
                              </vs-tr>
                              <vs-tr>
                                 <vs-td class="bold">Éxito</vs-td>
                                 <vs-td>2% del <b>total</b> pagado<br> (rendimiento + capital) </vs-td>
                                 <vs-td>10% solo del <b>rendimiento</b> pagado</vs-td>
                              </vs-tr>
                              <vs-tr>
                                 <vs-td class="bold">Transacción</vs-td>
                                 <vs-td>N/A</vs-td>
                                 <vs-td>2% del retiro por <b>liquidez <br> anticipada*</b></vs-td>
                              </vs-tr>
                              <vs-tr>
                                 <vs-td class="bold">Aclaración <br> improcedente</vs-td>
                                 <vs-td>N/A</vs-td>
                                 <vs-td>$500 por caso</vs-td>
                              </vs-tr>
                              <vs-tr>
                                 <vs-td class="bold">Contracargo <br> improcedente</vs-td>
                                 <vs-td>N/A</vs-td>
                                 <vs-td>$500 por caso</vs-td>
                              </vs-tr>
                           </template>
                        </vs-table>
                        <small>
                           <p>
                              *Solo aplica para liquidez obtenida en mercado secundario. Retirar tu saldo disponible no
                              genera
                              comisión.
                           </p>
                           <p class="mt-3">
                              Los cambios en las comisiones aplicarán a partir de que firmes tu nuevo contrato.
                           </p>
                           <p class="mt-3">Todas las <a class="inline-link" href="https://www.redgirasol.com/comisiones"
                                 target="_blank" rel="noopener">comisiones</a> son más IVA.</p>
                        </small>
                     </div>
                  </div>
                  <div class="flex flex-wrap justify-center">
                     <vs-button class="mt-5" color="dark" @click.stop="popUpSecondaryMarketStep++">Continuar</vs-button>
                  </div>
               </div>
               <template v-if="popUpSecondaryMarketStep == 2">
                  <div class="vx-row">
                     <div class="vx-col w-full" style="height:400px; overflow-y:scroll">
                        <latest-investor-merchant-contract :fill-user-data="true" />
                     </div>
                  </div>
                  <div class="flex flex-wrap justify-center">
                     <vs-button class="mt-base" color="dark" @click.stop="popUpSecondaryMarketStep++">Firmar</vs-button>
                  </div>
               </template>
               <template v-if="popUpSecondaryMarketStep == 3">
                  <div class="vx-row">
                     <div class="vx-col w-full">
                        <h5>Mercado secundario</h5>
                        <p class="mt-5">
                           Solicito a RedGirasol participar en el mercado secundario para diversificar mis inversiones y
                           tener la posibilidad de obtener liquidez anticipada.
                        </p>
                        <div class="checkbox-display mt-5">
                           <vs-checkbox name="terms" v-model.lazy="acceptSecondaryMarketTerms">
                           </vs-checkbox>
                           <p>Entiendo y estoy de acuerdo con los <br>
                              <a class="inline-link-regular" target="_blank" rel="noopener"
                                 @click="popUpSecondaryMarketTermsContract = true">Términos y Condiciones del Mercado
                                 Secundario</a>.
                           </p>
                        </div>
                     </div>
                  </div>
                  <div class="flex flex-wrap justify-center">
                     <vs-button class="mt-base" color="dark" :disabled="!acceptSecondaryMarketTerms"
                        @click.stop="signSecondaryMarketContracts">Autorizar y continuar</vs-button>
                  </div>
               </template>
            </div>
         </div>
         <!-- Popup para ver terminos y condiciones de mercado secundario -->
         <vs-popup :active.sync="popUpSecondaryMarketTermsContract"
            title="Términos y condiciones" class="extended-popup header-primary">
            <latest-secondary-market-terms-conditions-contract v-if="popUpSecondaryMarketTermsContract" />
         </vs-popup>
      </vs-popup>

      <!-- Popup para ver terminos y condiciones de inversion automatica -->
      <vs-popup v-if="popUpAutomaticInvestmentTermsContract" :active.sync="popUpAutomaticInvestmentTermsContract"
         title="Términos y condiciones" class="extended-popup header-primary">
         <latest-automatic-investment-terms-conditions-contract />
      </vs-popup>

      <!-- Popup para ver datos para abonar fondos -->
      <vs-popup v-if="popUpPayFunds" :active.sync="popUpPayFunds" title="Abona a tu cuenta"
         class="header-primary">
         <stp-account-info-minimal class="p-5" :stpClabe="stpClabe" />
      </vs-popup>

      <!-- Componente para obtener la geolocalización -->
      <rg-geolocator ref="rgGeolocator" />
   </div>
</template>

<script>
import formatHelper from "@mixins/formatHelper";
import stpHelper from "@mixins/stpHelper";
import dateHelper from "@mixins/dateHelper";
import investorInfoHelper from "@/helpers/investorInfoHelper";
import LatestInvestorMerchantContract from "@views/investor/contracts/LatestInvestorMerchantContract.vue";
import LatestSecondaryMarketTermsConditionsContract from "@views/investor/contracts/LatestSecondaryMarketTermsConditionsContract.vue";
import LatestAutomaticInvestmentTermsConditionsContract from "@views/investor/contracts/LatestAutomaticInvestmentTermsConditionsContract.vue";
import StpAccountInfoMinimal from "@components/stp/StpAccountInfoMinimal";

export default {
   components: {
      LatestInvestorMerchantContract,
      LatestSecondaryMarketTermsConditionsContract,
      LatestAutomaticInvestmentTermsConditionsContract,
      StpAccountInfoMinimal,
   },
   mixins: [formatHelper, stpHelper, dateHelper, investorInfoHelper],
   data() {
      return {
         isMounted: false,
         stpClabe: null,
         user_person_type: null,
         totalPortfolio: 0,
         investmentCommitments:0,
         activeFixedInvestment:0,
         minFixedInvestmentRequiredToSignUpSm: 0,
         minFinalBalanceRequiredToSignUpSm: 0,
         alreadyInvested: false,
         popUpSecondaryMarketInvitationTitle: "",
         popUpSecondaryMarketInvitationActive: false,
         popUpSecondaryMarketStep: 1,
         acceptSecondaryMarketTerms: false,
         popUpSecondaryMarketTermsContract: false,
         popUpAutomaticInvestmentTermsContract: false,
         popUpPayFunds: false,
         contractSecondaryMarketType: 26,
      }
   },
   watch: {
      popUpSecondaryMarketInvitationActive: function (newv, oldv) {
         if (newv === false) {
            this.popUpSecondaryMarketStep = 1;
         }
      }
   },
   computed: {
      isMexicanPM() {
         return this.user_person_type === 0;
      },
      isMexicanPF() {
         return this.user_person_type === 1;
      },
      popUpSecondaryMarketInvitationTitleDynamic() {
         let title = "";
         switch (this.popUpSecondaryMarketStep) {
            case 0:
            case 1:
            case 2:
               title = "Tu nuevo contrato";
               break;
            case 3:
               title = "Consentimiento";
               break;
            default:
               break;
         }
         return title;
      },
      minInvestmentToSecondaryMarket() {
         return this.minFixedInvestmentRequiredToSignUpSm - this.activeFixedInvestment;
      },
      minInvestmentToSecondaryMarketReached() {
         return this.minInvestmentToSecondaryMarket <= 0;
      },
      secondaryMarketStepsCount() {
         let total = 0;
         if (this.minInvestmentToSecondaryMarketReached) {
            total++;
         }
         if (this.InvestorSecondaryMarketContractSigned) {
            total++;
         }
         return total;
      },
      canSignSecondaryMarketContract() {
         return this.minInvestmentToSecondaryMarketReached;
      }
   },
   async beforeMount() {
      this.user_person_type = parseInt(this.UserPersonType);
   },
   async mounted() {
      this.isMounted = false;
      await this.showLoading(true)
      await this.getDataForHome();
      await this.checkForStpInfo();
      this.isMounted = true
      await this.showLoading(false)
   },
   methods: {
      async getDataForHome() {
         try {
            let response = await axios.get(`api/v2/investor/${this.InvestorId}/get-data-for-home-page-ms`);
            // DATOS PARA VERSION ACTUAL DE HOME
            this.totalPortfolio = response.data.totalPortfolio;
            this.investmentCommitments = response.data.investmentCommitments;
            this.activeFixedInvestment = response.data.activeFixedInvestment;
            this.minFixedInvestmentRequiredToSignUpSm = response.data.minFixedInvestmentRequiredToSignUpSm;
            this.minFinalBalanceRequiredToSignUpSm = response.data.minFinalBalanceRequiredToSignUpSm;
            this.alreadyInvested = response.data.alreadyInvested;
         }
         catch (e) {
            this.warn(e);
         }
      },
      async checkForStpInfo() {
         // solicitar informacion de stp solo si corresponde
         // panel completo y persona fisica mexicana
         if (this.isMexicanPF || this.isMexicanPM) {
            this.stpClabe = await this.getInvestorAccountStpClabe(this.InvestorId);
         }
      },
      showSecondaryMarketPopUp() {
         // se pide la geolocalizacion para la operacion
         this.$refs.rgGeolocator.askForGeolocation();
         this.popUpSecondaryMarketInvitationActive = true;
      },
      async signSecondaryMarketContracts() {
         this.showLoading(true, "Guardando información...");
         try {
            const payload = { geolocation: this.CurrentGeolocationData }
            await axios.post(`/api/v2/investor/${this.InvestorId}/sign-secondary-market-contracts`, payload);
            this.popUpSecondaryMarketInvitationActive=false;
            this.$emit('on-signed', 1);
            this.showLoading(false);
         } catch (e) {
            this.showLoading(false);
            this.errorNotifDialog("Error en la operación", e);
         }
      }
   }
};
</script>

<style>
.checkbox-display {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: flex-start;
}
</style>