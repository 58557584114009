<template>
  <div class="carousel-example">
    <!-- swiper1 -->
    <swiper :options="swiperOptions" class="swiper" ref="swiperTop">
      <swiper-slide v-for="img in images" :key="img.index">
        <vue-load-image class="horizontal-alignment">
          <img slot="image" class="responsive clickable-img" :src="img.url" alt="banner"
            @click="showImageDetail(img)">
          <img slot="preloader" :src="loader" alt="loader" />
          <img slot="error" :src="placeholder" alt="placeholder" class="img-responsive" />
        </vue-load-image>
      </swiper-slide>
      <div class="swiper-pagination swiper-pagination-custom" slot="pagination"></div>
      <div class="swiper-button-prev swiper-button-black" slot="button-prev"></div>
      <div class="swiper-button-next swiper-button-black" slot="button-next"></div>
    </swiper>

    <div class="con-upload vx-upload">
      <vx-view-upload v-if="showImage" :src="currentUrl" @on-close-view="closeImage()" />
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VxViewUpload from "@upload/VxViewUpload";
import VueLoadImage from 'vue-load-image'
export default {
  name: "FullImageCarousel",
  props: {
    images: { type: Array, required: true },
    clickAvailable: { type: Boolean, default: true }
  },
  components: {
    Swiper,
    SwiperSlide,
    VxViewUpload,
    VueLoadImage
  },
  data() {
    return {
      swiperOptions: {
        spaceBetween: 30,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      showImage: false,
      currentName: null,
      currentUrl: null,
      loader: require('@assets/images/carousel/loader-green.gif'),
      placeholder: require('@assets/images/pages/placeholder-image.png'),
    }
  },
  methods: {
    async showImageDetail(img) {
      if (this.clickAvailable) {
        this.currentName = img.filename;
        this.currentUrl = img.url;
        this.showImage = true;
      }
    },
    async closeImage() {
      this.showImage = false;
    }
  }
}
</script>

<style lang="scss">
.swiper-pagination-custom {
  .swiper-pagination-bullet-active {
    background: rgba(var(--vs-dark), 1) !important;
  }
}
</style>
