<template>
  <div>
    <!-- BANNER PORTAFOLIO RG HORIZONTAL -->
    <vx-card v-if="!isVertical" title-color="#fff" content-color="#fff"
        :card-background="'url(https://cdn.redgirasol.com/investors/home/banner-rg-portfolio.png)'">

        <h2 class="text-white">Portafolio RedGirasol</h2>
        <div class="vx-row mt-base mb-base">

          <div class="vx-col w-full">
              <!-- <div class="flex justify-center px-3"> -->
              <div class="vx-row">
                <div class="vx-col">
                    <p class="h3-normal">Rendimiento</p>
                    <h4 class="text-white">{{ decimalPercentFormat(marketYield, 1) }}</h4>
                </div>
                <div class="vx-col">
                    <p class="h3-normal">Cartera Total</p>
                    <h4 class="text-white">{{ moneyFormat(debitBalance) }} </h4>
                </div>
                <div class="vx-col">
                    <p class="h3-normal">Proyectos</p>
                    <h4 class="text-white">{{ totalProjects }}</h4>
                </div>
                <div class="vx-col mt-base">
                    <label>*Rendimiento anual promedio estimado de los últimos 12 meses antes de
                      comisiones.</label>
                </div>
              </div>
              <!-- </div> -->
          </div>
        </div>
    </vx-card>

    <!-- BANNER PORTAFOLIO RG VERTICAL-->
    <vx-card v-else title-color="#fff" content-color="#fff" card-background="#1C180D" style="height: 100%;">
        <div class="flex items-center flex-grow justify-between mt-5">
          <h2 class="text-white">Cartera RedGirasol</h2>
          <img src="https://cdn.redgirasol.com/investors/home/flor-icon.png"
          style="width: 50px" alt="">
        </div>

        <div class="vx-row mt-base">
          <div class="vx-col w-full">
              <div class="vx-row">
                <div class="vx-col w-full">
                    <h4 class="text-white">{{ decimalPercentFormat(marketYield, 1) }}</h4>
                    <p class="text-white">Rendimiento anual</p>
                </div>
                <div class="vx-col w-full mt-5">
                  <h4 class="text-white">{{ moneyFormat(debitBalance) }} </h4>
                  <p class="text-white">Cartera Total</p>
                </div>
                <div class="vx-col w-full mt-5">
                  <h4 class="text-white">{{ totalProjects }}</h4>
                  <p class="text-white">Proyectos</p>
                </div>
                <div class="vx-col mt-5 w-full">
                    <small>*Rendimiento anual promedio estimado de los últimos 12 meses antes de
                      comisiones.</small>
                </div>
              </div>
          </div>
        </div>
    </vx-card>

  </div>
</template>
<script>
import formatHelper from "@mixins/formatHelper";

export default {
  props: {
    isVertical: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [formatHelper],
  data() {
    return {
      marketYield: 0,
      debitBalance: 0,
      totalProjects: 0,
    }
  },
  async beforeMount() {
    this.showLoading(true)
    await this.getSecondaryMarketRgInfo();
    this.showLoading(false)
  },
  methods: {
    async getSecondaryMarketRgInfo() {
      try {
        await axios.get('/api/secondary-market-rg-stats').then((response) => {
          this.marketYield = response.data.yield;
          this.debitBalance = response.data.debit_balance;
          this.totalProjects = response.data.total_projects;
        });
      }
      catch (e) {

      }
    }
  }
}
</script>

<style scoped>
.border-green {
  border-color: #188414;
  border-style: solid;
  border-width: 2px;
  border-radius: 10px;
}
</style>
