<template>
   <div id="inicio" class="user-start-no">
      <div v-if="isMounted">
         <!-- PANEL DE CUMPLIMIENTO -->
         <compliance-banner @loadedRequirements="loadedRequirements" class="mb-base" />

         <!-- SI EL INVERSIONISTA NO SE ENCUENTRA ENROLADO EN EL MERCADO SECUNDARIO -->
         <template v-if="!InvestorRegisteredOnSecondaryMarket">
            <!-- SI YA FIRMO EL CONTRATO DEL MERCADO SECUNDARIO -->
            <template v-if="InvestorSecondaryMarketContractSigned">
               <div class="vx-row">
                  <div class="vx-col lg:w-1/2 w-full">
                     <vx-card class="text-center p-5">
                        <full-image-carousel :images="carouselImagesInviteSm" v-if="carouselImagesInviteSm.length > 0" />
                     </vx-card>
                  </div>

                  <div class="vx-col lg:w-1/2 w-full mb-1">
                     <vx-card>
                        <div slot="no-body">
                           <div class="w-full p-12" style="background-color: #c3f3a9;">
                              <div class="flex flex-wrap items-center flex-grow justify-between" style="height: 100%;">
                                 <h1 class="bold" style="color: #258f51;">Tu nueva inversión está muy cerca</h1>
                                 <img src="https://cdn.redgirasol.com/investors/home/portfolio-icon.png"
                                 style="width: 120px" alt="" class="mr-5">
                              </div>
                           </div>
                        </div>
                        <div class="mb-base">
                           <h2 class="bold mt-6">
                              A partir de mañana tu inversión será más estable y líquida que nunca. 
                           </h2>                           
                           <ul class="ml-base mt-base" style="font-size: 20px;">
                           <li>Tu portafolio se diversificará en cientos de proyectos</li>
                           <li>Mayor frecuencia de pagos</li>
                           <li>Podrás retirar anticipadamente tu capital</li>
                           <li>Generarás rendimientos más estables</li>
                           </ul>
                        </div>
                     </vx-card>
                     <!-- BANNER PORTFOLIO RG -->
                     <rg-portfolio-banner class="mt-base"/>
                  </div>
               </div>

            </template>
            <template v-else>
               <!-- BANNER PREREGISTRO MERCADO SECUNDARIO -->
               <div class="vx-row">
                  <div class="vx-col xl:w-1/2 w-full mb-1 mt-5">
                     <vx-card style="height: 100%;">
                        <div slot="no-body">
                           <image-overlay bg-url="https://cdn.redgirasol.com/investors/home/banner-mercado.png" 
                              title="Simplifica tu inversión"
                              subtitle="Cobra rendimientos diarios y deja de preocuparte por escoger proyectos."
                              text-color="#258F51" />
                        </div>
                        <div class="ml-2 mt-2">
                           <secondary-market-requirements @on-signed="onSignedSmContract" />
                        </div>
                     </vx-card>
                  </div>


                  <div class="vx-col xl:w-1/2 w-full">
                     <!-- <vx-card> -->
                        <div class="vx-row">
                           <div class="vx-col xl:w-1/2 xxl:w-1/3 w-full mb-5">
                              <rg-portfolio-banner class="mt-5" :is-vertical="true" style="height: 100%;"/>                              
                           </div>
                           <div class="vx-col xl:w-1/2 xxl:w-2/3 w-full">
                              <div class="vx-row">
                                 <div class="vx-col w-full">
                                    <vx-card card-background="#A2E19D" class="mt-5">
                                       <div class="flex items-center flex-grow justify-between ml-5 mr-5">
                                          <h2>Recibe pagos diarios</h2>
                                          <img src="https://cdn.redgirasol.com/investors/home/dinero-icon.png"
                                          style="width: 120px" alt="">
                                       </div>
                                       <p class="ml-5 mr-5 mb-2 mt-5">
                                          Participarás en tantos proyectos que ganarás rendimientos todos los días
                                       </p>
                                    </vx-card>
                                 </div>
                                 <div class="vx-col w-full">
                                    <vx-card card-background="#D6FA9D" class="mt-5">
                                       <div class="flex items-center flex-grow justify-between ml-5 mr-5">
                                          <h2>Invertir ahora es fácil</h2>
                                          <img src="https://cdn.redgirasol.com/investors/home/tarjetas-icon.png"
                                          style="width: 120px" alt="">
                                       </div>
                                       <p class="ml-5 mr-5 mb-2">
                                          Solo tendrás que depositar en tu cuenta. Tu dinero se diversificará automáticamente en la cartera de RedGirasol 
                                       </p>
                                    </vx-card>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="vx-row">
                           <div class="vx-col xxl:w-2/3 w-full mb-5">
                              <vx-card card-background="#A1EA9B" style="height: 100%;" class="mt-5">
                                 <div class="flex items-center flex-grow justify-between ml-5 mr-5">
                                    <h2>Tu dinero crece exponencialmente</h2>
                                    <img src="https://cdn.redgirasol.com/investors/home/maletin-icon.png"
                                    style="width: 120px" alt="">
                                 </div>
                                 <p class="ml-5 mr-5 mb-2 mt-5">
                                    Participarás en tantos proyectos que ganarás rendimientos todos los días
                                 </p>
                              </vx-card>
                           </div>
                           <div class="vx-col xxl:w-1/3 w-full mb-5">
                              <vx-card card-background="#DBEDD9" style="height: 100%;" class="mt-5">
                                 <div class="flex items-center flex-grow justify-between ml-5 mr-5">
                                    <h2>Liquidez oportuna</h2>
                                    <img src="https://cdn.redgirasol.com/investors/home/derecha-icon.png"
                                    style="width: 60px" alt="">
                                 </div>
                                 <p class="ml-5 mr-5 mb-2 mt-5">
                                    Solicita retiros cuando lo desees sin esperar que concluyan los proyectos.
                                 </p>
                              </vx-card>
                           </div>
                        </div>



                        <!-- <full-image-carousel :images="carouselImagesInviteSm" v-if="carouselImagesInviteSm.length > 0" /> -->
                     <!-- </vx-card> -->
                  </div>

               </div>
            </template>

            <!-- BANNER AUMENTA TU NIVEL -->
            <div class="mt-5" v-if="mainComplianceRequirements <= 0">
               <vx-card v-if="isMounted">
                  <investor-contract-level-banner/>
               </vx-card>
            </div>
         </template>
         <!-- SI EL INVERSIONISTA YA SE ENCUENTRA EN EL MERCADO SECUNDARIO -->
         <template v-else>
            <!-- BANNER AVISOS LIMITES MERCADO SECUNDARIO -->
            <template>
               <!-- si el usuario no ha invertido -->
               <template v-if="!alreadyInvested">
                  <vx-card v-if="activeFixedInvestment <= 0" class="mb-base mt-5" card-background="#E4E4E4">
                     <div class="vx-row">
                        <div class="vx-col w-full">
                           <div slot="header" class="flex flex-wrap items-center flex-grow justify-between">
                              <div class="vx-row mt-3 mb-3" style="display: unset;">
                                 <div class="vx-col">
                                    <h2 class="bold">Haz tu primera inversión</h2>
                                 </div>
                                 <div class="vx-col">
                                    <label>Transfiere fondos desde la app de tu banco las veces que desees y alcanza
                                       el mínimo de {{ explicitMoneyFormat(minFixedInvestmentRequiredToSignUpSm) }}
                                       para continuar invirtiendo.</label><br>
                                    <label>Tienes 30 días para alcanzar el mínimo de inversión. De lo contrario, tu
                                       inversión será retirada y transferida a tu cuenta bancaria.</label>
                                 </div>
                              </div>
                              <vs-button class="ml-auto" type="border" color="dark"
                                 @click.stop="popUpPayFunds = true">Abonar</vs-button>
                           </div>
                        </div>
                     </div>
                  </vx-card>
               </template>
               <!-- si el usuario ya invirtio y esta por debajo del minimo a entrar al ms -->
               <vx-card v-else-if="activeFixedInvestment < minFixedInvestmentRequiredToSignUpSm" class="mb-base mt-5"
                  card-background="#E4E4E4">
                  <div class="vx-row">
                     <div class="vx-col w-full">
                        <div slot="header" class="flex flex-wrap items-center flex-grow justify-between">
                           <div class="vx-row mt-3 mb-3" style="display: unset;">
                              <div class="vx-col">
                                 <h2 class="bold">Te faltan {{
                                    explicitMoneyFormat(minFixedInvestmentRequiredToSignUpSm - activeFixedInvestment) }}
                                    para alcanzar el mínimo de inversión</h2>
                              </div>
                              <div class="vx-col">
                                 <label>Alcanzar el mínimo es importante para que tu portafolio pueda
                                    diversificarse</label><br>
                                 <label>Si el valor de tu inversión no alcanza al menos {{
                                    explicitMoneyFormat(minFixedInvestmentRequiredToSignUpSm) }}, retiraremos tu
                                    inversión y será transferida a tu cuenta bancaria.</label>
                              </div>
                           </div>
                           <vs-button class="ml-auto" type="border" color="dark"
                              @click.stop="popUpPayFunds = true">Abonar</vs-button>
                        </div>
                     </div>
                  </div>
               </vx-card>
            </template>

            <!-- BANNER MI CUENTA DE INVERSION -->
            <vx-card class="mt-base">
               <div class="vx-row">
                  <div class="vx-col w-full">
                     <h1 class="mt-5">Mi cuenta de inversión</h1>
                  </div>
               </div>
               <div class="vx-row mb-base">
                  <!-- valor de mi portafolio -->
                  <div class="vx-col xl:w-1/2 w-full p-5">
                     <div class="vx-row" style="background-color: #D9D9D933; border-radius: 20px; height: 100%;">
                        <div class="vx-col w-full mt-base mb-base">
                           <div class="flex flex-wrap items-center flex-grow justify-center" style="height: 100%;">
                              <img src="https://cdn.redgirasol.com/investors/home/portfolio-icon.png"
                                 style="width: 120px" alt="" class="mr-5">
                              <div class="vx-row">
                                 <div class="vx-col">
                                    <h2 class="bold">{{ explicitMoneyFormat(totalPortfolio) }}</h2>
                                    <p class="h3-normal">Valor de mi portafolio</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="vx-col w-full">
                           <label class="bold">Resumen de tu portafolio</label>
                           <div class="vx-row">
                              <div class="vx-col w-1/3 p-5">
                                 <p class="bold">{{ explicitMoneyFormat(activeFixedInvestment) }}</p>
                                 <p>Inversión efectiva</p>
                              </div>
                              <div class="vx-col w-1/3 p-5">
                                 <p class="bold">{{ explicitMoneyFormat(availableToInvest) }}</p>
                                 <p>Saldo disponible</p>
                              </div>
                              <div class="vx-col w-1/3 p-5">
                                 <p class="bold">{{ explicitMoneyFormat(investmentCommitments) }}</p>
                                 <p>Compromiso de inversión</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <!-- rendidmientos generados -->
                  <div class="vx-col xl:w-1/2 w-full p-5">
                     <div class="vx-row" style="background-color: #D9D9D933; border-radius: 20px; height: 100%;">
                        <div class="vx-col w-full mt-base mb-base">
                           <div class="flex flex-wrap items-center flex-grow justify-start ml-5" style="height: 100%;">
                              <img src="https://cdn.redgirasol.com/investors/home/returns-icon.png" style="width: 120px"
                                 alt="" class="mr-5">
                              <div class="vx-row">
                                 <div class="vx-col">
                                    <h2 class="bold">{{ explicitMoneyFormat(generatedReturns) }}</h2>
                                    <p class="h3-normal">Rendimientos generados</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="vx-col w-full">
                           <div class="vx-row mt-base">
                              <div class="vx-col w-full p-5">
                                 <div class="flex flex-wrap items-center flex-grow justify-start mb-1">
                                    <div class="mr-2" v-for="(item, index) in periodReturnsOptions" :key="index">
                                       <rg-radio class="w-full" groupName="periodReturnsOptions" :label="item.label"
                                          :value="item.value" :desc="item.desc" v-model="periodReturns" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="vx-row mt-base mb-base">
                  <div class="vx-col w-full">
                     <div class="flex justify-center px-3">
                        <vs-button color="primary" @click.stop="popUpPayFunds = true">Abonar</vs-button>
                        <vs-button class="ml-5" color="primary" type="border" :disabled="mainComplianceRequirements > 0"
                           @click.stop="popUpWithdrawFunds = true">Retirar</vs-button>
                     </div>
                  </div>
               </div>
            </vx-card>

            <div class="vx-row">
               <div class="vx-col lg:w-1/2 w-full">
                  <vx-card class="mt-base">
                     <monthly-auto-invest-banner :show-carousel="false"/>
                  </vx-card>
               </div>
               <div class="vx-col lg:w-1/2 w-full">
                  <!-- BANNER PORTAFOLIO RG -->
                  <rg-portfolio-banner class="mt-base"/>
               </div>
            </div>


            <div class="vx-row mt-5">
               <div class="vx-col w-full">
                  <div class="flex">
                     <feather-icon icon="InfoIcon" svgClasses="w-8 h-8 text-primary" class="mr-3" />
                     <span class="bold mt-1 italic">El valor de tu portafolio es la suma del saldo disponible para
                        inversión, los
                        compromisos de inversión y el monto de inversión efectiva.</span>
                  </div>
               </div>
            </div>
         </template>
      </div>

      <!-- Popup para carrusel de mercado secundario -->
      <vs-popup v-if="isMounted" :active.sync="showSecondaryMarketCarousel" class="mid-popup no-header">
         <full-image-carousel :images="carouselImagesSm" :click-available="false" v-if="carouselImagesSm.length > 0" />
      </vs-popup>

      <!-- Popup para ver datos para retirar fondos -->
      <vs-popup v-if="popUpWithdrawFunds" :active.sync="popUpWithdrawFunds" title="Retirar"
         class="header-primary">
         <withdraw-info-sm-pop-up @on-saved="onWithdrawalSaved" @on-closed="onWithdrawalClosed" />
      </vs-popup>

      <!-- Popup para ver datos para abonar fondos -->
      <vs-popup v-if="popUpPayFunds" :active.sync="popUpPayFunds" title="Abona a tu cuenta"
         class="header-primary">
         <stp-account-info-minimal class="p-5" :stpClabe="stpClabe" />
      </vs-popup>

      <!-- Popup de exito al firmar el contrato de ms -->
      <vs-popup v-if="smContractSignedSuccessPopUp" :active.sync="smContractSignedSuccessPopUp"
         class="mid-popup no-header">
         <div class="vx-row">
            <div class="vx-col w-full">
               <img src='https://cdn.redgirasol.com/investors/home/success_secondary_market_bnner.png'
                  class="responsive">
               <div class="text-center p-5">
                  <h3 class="mb-2">Pronto tu inversión contará con más liquidez y diversificación que nunca. Te
                     notificaremos por correo cuando suceda el cambio.</h3>
               </div>
            </div>
         </div>
         <div class="flex flex-wrap justify-center">
            <vs-button color="dark" @click.stop="smContractSignedSuccessPopUp = false">Finalizar</vs-button>
         </div>
      </vs-popup>

      <!-- Componente para obtener la geolocalización -->
      <rg-geolocator ref="rgGeolocator" />
      <!-- TERMINA INVITACION A MERCADO SECUNDARIO -->
   </div>
</template>

<script>

import ComplianceBanner from "@components/compliance/ComplianceBanner";
import formatHelper from "@mixins/formatHelper";
import stpHelper from "@mixins/stpHelper";
import dateHelper from "@mixins/dateHelper";
import userIdentityHelper from "@/helpers/userIdentityHelper";
import investorInfoHelper from "@/helpers/investorInfoHelper";
import FundReady from "@components/compliance/FundReady";
import { mapState } from "vuex";
import InvestorContractLevelBanner from "@components/investor/plans/InvestorContractLevelBanner";
import FullImageCarousel from "@components/shared/FullImageCarousel";
import AutomaticInvestmentButton from "@components/investor/plans/AutomaticInvestmentButton";
import LatestInvestorMerchantContract from "@views/investor/contracts/LatestInvestorMerchantContract.vue";
import LatestSecondaryMarketTermsConditionsContract from "@views/investor/contracts/LatestSecondaryMarketTermsConditionsContract.vue";
import LatestAutomaticInvestmentTermsConditionsContract from "@views/investor/contracts/LatestAutomaticInvestmentTermsConditionsContract.vue";
import StpAccountInfoMinimal from "@components/stp/StpAccountInfoMinimal";
import WithdrawInfoSmPopUp from "@components/investor/invested/WithdrawInfoSmPopUp";
import SecondaryMarketRequirements from "@components/investor/secondary-market/SecondaryMarketRequirements";
import RgPortfolioBanner from "@components/investor/secondary-market/RgPortfolioBanner";
import MonthlyAutoInvestBanner from "@components/investor/plans/MonthlyAutoInvestBanner";

const periodOptions = [
    { label: "Histórico", value: "historic", desc: null },
    { label: "Últimos 12 meses", value: "year", desc: null },
    { label: "Último mes", value: "month", desc: null },
];

export default {
   components: {
      ComplianceBanner,
      FundReady,
      InvestorContractLevelBanner,
      FullImageCarousel,
      AutomaticInvestmentButton,
      LatestInvestorMerchantContract,
      LatestSecondaryMarketTermsConditionsContract,
      LatestAutomaticInvestmentTermsConditionsContract,
      StpAccountInfoMinimal,
      WithdrawInfoSmPopUp,
      SecondaryMarketRequirements,
      RgPortfolioBanner,
      MonthlyAutoInvestBanner
   },
   mixins: [formatHelper, stpHelper, dateHelper, userIdentityHelper, investorInfoHelper],
   data() {
      return {
         periodReturnsOptions: periodOptions,
         showSecondaryMarketCarousel: false,
         smContractSignedSuccessPopUp: false,
         isMounted: false,
         requirements: [],
         identity: null,
         identityStatus: 0,
         funds: 0,
         hasAutomaticInvestmentInactive: false,
         showInvestmentCard: false,
         stpClabe: null,
         user_person_type: null,
         availableToInvest: 0,
         investmentCommitments: 0,
         activeFixedInvestment: 0,
         totalPortfolio: 0,
         generatedReturns:0,
         periodReturns:'historic',
         minFixedInvestmentRequiredToSignUpSm: 0,
         minFinalBalanceRequiredToSignUpSm: 0,
         alreadyInvested: false,
         figs: {
            arrows: require('@assets/images/investor-figs/home-arrows-up.svg'),
            portfolio: require('@assets/images/investor-figs/home-portfolio.svg'),
            invest: require('@assets/images/investor-figs/home-invest.svg'),
            commitment: require('@assets/images/investor-figs/home-commitment.svg'),
            fixed: require('@assets/images/investor-figs/home-fixed-investment.svg'),
         },
         mainComplianceRequirements: 0,
         carouselImagesInviteSm: [
            {
               index: 0,
               url: "https://cdn.redgirasol.com/investors/home/sm_invite_carousel/sm-carousel-post-1.png",
               filename: ""
            },
            {
               index: 1,
               url: "https://cdn.redgirasol.com/investors/home/sm_invite_carousel/sm-carousel-post-2.png",
               filename: ""
            },
            {
               index: 2,
               url: "https://cdn.redgirasol.com/investors/home/sm_invite_carousel/sm-carousel-post-3.png",
               filename: ""
            },
            {
               index: 3,
               url: "https://cdn.redgirasol.com/investors/home/sm_invite_carousel/sm-carousel-post-4.png",
               filename: ""
            }
         ],
         carouselImagesSm: [
            {
               index: 0,
               url: "https://cdn.redgirasol.com/investors/home/secondary_market_sostenible.png",
               filename: ""
            },
            {
               index: 1,
               url: "https://cdn.redgirasol.com/investors/home/secondary_market_automaticamente.png",
               filename: ""
            },
            {
               index: 2,
               url: "https://cdn.redgirasol.com/investors/home/secondary_market_comienza.png",
               filename: ""
            }
         ],
         popUpPayFunds: false,
         popUpWithdrawFunds: false,
      }
   },
   computed: {
      ...mapState("auth", ["roleGeneralData"]),
      isMexicanPM() {
         return this.user_person_type === 0;
      },
      isMexicanPF() {
         return this.user_person_type === 1;
      }
   },
   watch:{
      periodReturns: async function (old, newval){
      if(old !== newval){
        await this.postInvestorReturns();
      }
    }
   },
   async beforeMount() {
      this.user_person_type = parseInt(this.UserPersonType);
   },
   async mounted() {
      this.isMounted = false;
      await this.showLoading(true)
      await this.onUserInfoUpdated();
      await this.getDataForHome();
      await this.postInvestorReturns();
      // await this.getPlansInfo();
      await this.checkForStpInfo();
      await this.setCardsSettings();
      if (this.InvestorRegisteredOnSecondaryMarket && !this.alreadyInvested) {
         this.showSecondaryMarketCarousel=true;
      }
      this.isMounted = true
      await this.showLoading(false)
   },
   methods: {
      async getDataForHome() {
         try {
            let response = await axios.get(`api/v2/investor/${this.InvestorId}/get-data-for-home-page-ms`);

            // DATOS PARA VERSION ACTUAL DE HOME
            this.identity = response.data.identity;
            this.availableToInvest = response.data.availableToInvest;
            this.investmentCommitments = response.data.investmentCommitments;
            this.activeFixedInvestment = response.data.activeFixedInvestment;
            this.totalPortfolio = response.data.totalPortfolio;
            this.minFixedInvestmentRequiredToSignUpSm = response.data.minFixedInvestmentRequiredToSignUpSm;
            this.minFinalBalanceRequiredToSignUpSm = response.data.minFinalBalanceRequiredToSignUpSm;
            this.alreadyInvested = response.data.alreadyInvested;

            // SE VALIDAN DATOS PARA MOSTRAR O NO EL BANNER DE LA VERIFICACION DE IDENTIDAD
            if (this.identity) {
               this.identityStatus = this.identity.identity_status;
            }
         }
         catch (e) {
            this.warn(e);
         }
      },
      async postInvestorReturns() {
         await this.showLoading(true);
         try {
            let payload = {
               "period": this.periodReturns
            };
            let response = await axios.post(`api/v2/investor/generated-returns`, payload);
            this.generatedReturns = response.data.generated_returns;
         }
         catch (e) {
            this.warn(e);
         }
         await this.showLoading(false);
      },
      async changePeriodGeneratedReturns(period) {
         try {
            this.periodReturns = period;
            await this.postInvestorReturns();            
         }
         catch (e) {
            this.warn(e);
         }
      },
      async getPlansInfo() {
         try {
            const res = await axios.get(`api/v2/investor/${this.InvestorId}/getInvestorPlansInfo`);
            const investorPlan = res.data.plan;
            this.hasAutomaticInvestmentInactive = investorPlan.autoinvest_applicant_payments === 0;
         }
         catch (e) {
         }
      },
      async checkForStpInfo() {
         // solicitar informacion de stp solo si corresponde
         // panel completo y persona fisica mexicana
         if (this.isMexicanPF || this.isMexicanPM) {
            this.stpClabe = await this.getInvestorAccountStpClabe(this.InvestorId);
         }
      },
      async setCardsSettings() {
         // tarjeta: abonar fondos
         // Aparece cuando su saldo es igual a 0 y tiene clabe stp
         if (this.roleGeneralData.balance === 0 && this.stpClabe !== null) {
            this.showInvestmentCard = true;
            // this.$router.replace({name: 'abonarFondos'});
            return;
         }
      },
      async goToFunds() {
         await this.$router.push({ name: 'abonarFondos' });
      },
      async goToWithdrawFunds() {
         await this.$router.push({ name: 'retirarFondos' });
      },
      loadedRequirements(count) {
         this.mainComplianceRequirements = count;
      },
      async onSignedSmContract() {
         this.smContractSignedSuccessPopUp = true;
         await this.onUserInfoUpdated();
      },
      async onWithdrawalSaved() {
         this.popUpWithdrawFunds = false;
         await this.showLoading(true)
         await this.onUserInfoUpdated();
         await this.getDataForHome();
         await this.showLoading(false);         
      },
      async onWithdrawalClosed() {
         this.popUpWithdrawFunds = false;
      }
   }
};
</script>

<style scoped>
.big-label {
   font-size: 24px;
}

.big-label-sub {
   font-size: 20px;
}

.checkbox-display {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: flex-start;
}

li {
  list-style-type: disc;
}
</style>
