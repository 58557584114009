<template>
  <div>
    <div class="vx-row" v-if="hasError">
      <span class="error_text">Ha ocurrido un error mientras se obtenían los datos. Lamentamos el inconveniente.
        <a href="#" class="underline bold black" @click="reload">Intente recargando la página.</a></span>
    </div>

    <template v-if="!isSupplier && !hasError">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p :class="mbContractLevel">
            Registra tu CLABE personalizada en la app de tu banco y transfiere cuando lo desees.
          </p>
          <div class="vx-row">
            <div class="vx-col w-full">
              <investor-contract-level-banner :show-balance="false" :show-bar="false" :show-levels-info="false" @on-level-received="onLevelReceived"/>
            </div>
          </div>
          <p :class="mtContractLevel+' bold'">
            Cuenta CLABE
          </p>
        </div>
      </div>

      <div class="vx-row mt-5 mb-6">
        <div class="vx-col w-full">
          <div v-if="hasStpAccount" class="flex flex-wrap items-center flex-grow justify-start">
            <span>{{ stpClabe }} </span>
            <feather-icon icon="CopyIcon" svgClasses="w-5 h-5 text-dark" class="ml-4" @click.stop="copyToClipboard(stpClabe)" />
          </div>
          <span v-else class="sm:mx-8 mx-4 bold">Contáctanos a través del chat para generar tu cuenta STP y puedas
            realizar este proceso.</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="bold">
            Banco
          </p>
          <p>
            STP (Sistema de Transferencias y Pagos)
          </p>
        </div>
        <div class="vx-col w-full">
          <p class="mt-base bold">
            Beneficiario
          </p>
          <div class="flex flex-wrap items-center flex-grow justify-start">  
            <p>
              {{ rgName }}
            </p>
            <feather-icon icon="CopyIcon" svgClasses="w-5 h-5 text-dark" class="ml-4" @click.stop="copyToClipboard(rgName)" />
          </div>
        </div>
      </div>

      <div class="vx-row mt-base">
        <div class="vx-col w-full">
          <p class="bold">
            RFC
          </p>
          <div class="flex flex-wrap items-center flex-grow justify-start">
            <p>
              {{ rgRfc }}
            </p>
            <feather-icon icon="CopyIcon" svgClasses="w-5 h-5 text-dark" class="ml-4" @click.stop="copyToClipboard(rgRfc)" />
          </div>
        </div>
      </div>

      <div class="vx-row mt-6 mb-5">
        <div class="vx-col w-full">
          <div class="flex">
            <feather-icon icon="InfoIcon" svgClasses="w-5 h-5 text-dark" class="mr-3" />
            <small class="bold mt-1 italic">
              Solo podrás abonar desde una cuenta que esté a tu nombre. Si el nombre de la cuenta no coincide, los fondos serán devueltos a la cuenta de origen. 
            </small>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SuccessAlert from "@components/supplier/misc/SuccessAlert";
import InvestorContractLevelBanner from "@components/investor/plans/InvestorContractLevelBanner";
const RGNAME = "RedGirasol";
const RGRFC = "RGI160414TR9";

export default {
  props: ['user_id', 'stpClabe', 'isSupplier'],
  components: { SuccessAlert, InvestorContractLevelBanner },
  data() {
    return {
      rgName: RGNAME,
      rgRfc: RGRFC,
      hasError: false,
      loaded: false,
      mbContractLevel:"",
      mtContractLevel:""
    }
  },
  computed: {
    hasStpAccount() {
      if (this.isSupplier) {
        return this.stpClabe != null;
      } else {
        return true;
      }
    }
  },
  methods: {
    reload() {
      window.location.reload();
    },
    async copyToClipboard(value) {
      const el = document.createElement('textarea');
      el.value = value;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.successNotif('Copiado', "Información copiada al portapapeles");
    },
    onLevelReceived(value){
      if (value != 3) {
        this.mbContractLevel = "mb-base";
        this.mtContractLevel = "mt-base";        
      }
    }
  }
}
</script>

<style lang="css">
.rounded-space {
  border: 2px solid black;
  padding: 14px;
  font-family: 'gilroybold', 'sans-serif';
  font-size: 22px;
  margin-right: 15px;
  border-radius: 10px;
}

.rounded-icon img {
  height: 70px;
  padding: 8px;
  border: 2px solid black;
  border-radius: 10px;
  cursor: pointer;
}

.rounded-icon img:hover {
  background-color: #eaeaea;
}

.list-number {
  font-size: 16px;
  background-color: #28DE18;
  color: white;
  font-family: 'gilroybold';
  padding: 2px 11px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: inline-block;
  margin-right: 5px;
}

.bold-inline {
  font-family: 'gilroybold';
}

.mbot {
  margin-bottom: 15px;
}

.medium-bold-inline {
  font-family: 'gilroymedium';
}</style>
